<template>
  <div class="notes">
    <h4 v-if="heading">{{heading}}</h4>
    <p class="mt-8" v-if="intro">{{intro}}</p>
    <form class="mt-8" @submit.prevent="saveNotes">
      <textarea class="input-textarea" v-model="notes" />
      <div class="mt-4 text-right">
        <button type="submit" class="btn-transparent" :class="{'disabled': notesLoading}">Save</button>
      </div>
    </form>
  </div>
</template>

<script>
import api from '@/api'
export default {
  name: 'WineryNotes',
  props: {
    heading: {
      type: String,
      default: null
    },
    intro: {
      type: String,
      default: null
    },
    wineryId: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      notes: '',
      notesLoading: false
    }
  },
  methods: {
    getNotes () {
      this.notesLoading = true
      api.getWineryNotes(this.wineryId).then((res) => {
        this.notes = res.data.notes
        this.notesLoading = false
      })
    },
    saveNotes () {
      this.notesLoading = true
      api.addWineryNotes(this.wineryId, this.notes).then(() => {
        this.notesLoading = false
        this.$toasted.show("Saved notes", {
          theme: "toasted-primary",
          position: "top-right",
          duration : 5000
        });
      })
    }
  },
  mounted() {
    this.getNotes()
  }
}
</script>

<style scoped lang="scss">

</style>
