<template>
  <div class="winery">
    <explore-connect />
<!--    <div class="alpaca-popup" :class="{active: alpacaVisible}">-->
<!--      <div class="close cursor-pointer" @click="hideAlpaca">-->
<!--        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 29.165 29.165" class="stroke-current inline-block hover:text-grey-500">-->
<!--          <g id="X" transform="translate(0.582 0.582)">-->
<!--            <path id="Stroke_1" data-name="Stroke 1" d="M0,0,28,28" fill="none" stroke-miterlimit="10" stroke-width="1.647"/>-->
<!--            <path id="Stroke_3" data-name="Stroke 3" d="M28,0,0,28" fill="none" stroke-miterlimit="10" stroke-width="1.647"/>-->
<!--          </g>-->
<!--        </svg>-->
<!--      </div>-->
<!--      <div id="alpaca-container" class="alpaca-container">-->
<!--        <span class="loading">Loading...</span>-->
<!--      </div>-->
<!--    </div>-->

<!--    <script lang="text/javascript" type="application/javascript">-->
<!--      var API_URL = 'https://embed.alpacamaps.com/api/v1';-->
<!--      var ALPACA_API_URL_V2 = 'https://api.withalpaca.com/api/v2';-->
<!--    </script>-->
<!--    <script lang="text/javascript" type="application/javascript" v-if="winery"-->
<!--            src="https://cdn.alpacamaps.com/scripts/alpaca-widget@latest.js"-->
<!--            data-id="locale/wine-australia"-->
<!--            data-view-mode="default"-->
<!--            data-container-id="alpaca-container"-->
<!--            data-inline="true"-->
<!--            :data-content-path="`${winery.elements.winery_id.value}/info`"-->
<!--    ></script>-->
    <div class="loading flex flex-row items-center justify-center fixed left-0 top-20 md:top-40 lg:top-60 w-full bottom-0 z-100 bg-black transition-all duration-500" :class="[{'invisible opacity-0': winery}]">
      <loading-spinner />
    </div>
    <div class="page min-h-screen">
      <div v-if="winery">
        <div class="outer-container">
          <div class="inner-container">
            <div class="breadcrumbs mt-12 text-grey-400 text-12 uppercase">
              <router-link to="/expo" class="hover:text-white transition-colors">Expo</router-link><span class="divider inline-block px-4">&gt;</span>Wineries<span class="divider inline-block px-4">&gt;</span><span class="text-white">{{winery.elements.name.value}}</span>
            </div>
            <div class="hero mt-12 pb-16 md:pb-36 relative">
              <bleed-container bleed="left" class="hero-image-height">
                <div class="hero-image absolute left-0 top-0 w-full h-full z-10">
                  <img v-if="winery.elements.hero_image.value.length" :data-lowsrc="`${winery.elements.hero_image.value[0].url}?w=100&auto=format`" :data-srcset="`${winery.elements.hero_image.value[0].url}?w=767&h=250&fit=crop&auto=format 767w, ${winery.elements.hero_image.value[0].url}?w=1000&h=450&fit=crop&auto=format 1023w, ${winery.elements.hero_image.value[0].url}?w=2000&auto=format 2000w`" alt="Winery Name" class="lazyload block absolute left-0 top-0 w-full h-full object-cover max-w-none">
                  <div v-if="isViewed" class="viewed absolute bg-white bg-opacity-60 flex flex-row items-center text-black right-8 top-8 p-4 py-2 text-12">
                    <svg id="icon_eye" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="14" height="9" viewBox="0 0 14 9" class="mr-2">
                      <defs>
                        <clipPath id="clip-path">
                          <path id="Clip_2" data-name="Clip 2" d="M0,0H13.868V8.844H0Z" fill="none"/>
                        </clipPath>
                      </defs>
                      <g id="Group_6" data-name="Group 6" transform="translate(0 0.15)">
                        <g id="Group_3" data-name="Group 3" transform="translate(0 0)">
                          <path id="Clip_2-2" data-name="Clip 2" d="M0,0H13.868V8.844H0Z" transform="translate(0 0)" fill="none"/>
                          <g id="Group_3-2" data-name="Group 3" transform="translate(0 0)" clip-path="url(#clip-path)">
                            <path id="Fill_1" data-name="Fill 1" d="M6.934,8.844C2.413,8.844,0,5.141,0,4.422S2.413,0,6.934,0s6.934,3.7,6.934,4.422S11.455,8.844,6.934,8.844ZM6.934.6A6.96,6.96,0,0,0,.615,4.425a2.318,2.318,0,0,0,.417.788A7.15,7.15,0,0,0,2.226,6.505,7.183,7.183,0,0,0,6.934,8.244a6.96,6.96,0,0,0,6.319-3.826,2.318,2.318,0,0,0-.417-.788,7.15,7.15,0,0,0-1.194-1.291A7.183,7.183,0,0,0,6.934.6Z" transform="translate(0 0)"/>
                          </g>
                        </g>
                        <path id="Fill_4" data-name="Fill 4" d="M2.612,4.956A2.551,2.551,0,0,1,0,2.478,2.551,2.551,0,0,1,2.612,0,2.551,2.551,0,0,1,5.225,2.478,2.551,2.551,0,0,1,2.612,4.956ZM2.612.6a1.942,1.942,0,0,0-2,1.878,1.943,1.943,0,0,0,2,1.878,1.943,1.943,0,0,0,2-1.878A1.942,1.942,0,0,0,2.612.6Z" transform="translate(4.32 1.947)"/>
                      </g>
                    </svg>
                    You've viewed this page
                  </div>
                </div>
                <div class="bleed-wrapper relative z-20 h-full" v-if="winery.elements.winery_logo.value.length">
<!--                  <div class="sizer pb-5/10 lg:pb-4/10"></div>-->
                  <div class="logo-box bg-black p-6 md:p-16 lg:p-20 absolute right-0 -bottom-16 md:-bottom-36">
                    <div class="logo relative p-8 w-40 h-40 md:w-64 md:h-64 lg:w-100 lg:h-100 bg-grey-100">
                      <div class="logo-img absolute left-8 top-8 right-8 bottom-8">
                        <img :src="winery.elements.winery_logo.value[0].url" :alt="winery.elements.name.value" class="absolute object-contain object-center left-0 top-0 w-full h-full">
                      </div>
                    </div>
                  </div>
                </div>
              </bleed-container>
            </div>

            <div class="main-content relative mt-12">
              <div class="row md:flex md:flex-row md:items-start">
                <div class="main flex-grow">
                  <div class="top relative">
                    <div class="favourite absolute -top-12 md:-top-16 lg:top-0 lg:right-0 flex flex-row items-center cursor-pointer" @click="toggleFavourite">
                      <span v-if="!favouriteLoading">
                        <span v-if="isFavourite" class="text-primary-500">
                          <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M462.3 62.6C407.5 15.9 326 24.3 275.7 76.2L256 96.5l-19.7-20.3C186.1 24.3 104.5 15.9 49.7 62.6c-62.8 53.6-66.1 149.8-9.9 207.9l193.5 199.8c12.5 12.9 32.8 12.9 45.3 0l193.5-199.8c56.3-58.1 53-154.3-9.8-207.9z"></path></svg>
                        </span>
                        <span v-if="!isFavourite">
                          <svg width="15" height="15" aria-hidden="true" focusable="false" data-prefix="far" data-icon="heart" class="svg-inline--fa fa-heart fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M458.4 64.3C400.6 15.7 311.3 23 256 79.3 200.7 23 111.4 15.6 53.6 64.3-21.6 127.6-10.6 230.8 43 285.5l175.4 178.7c10 10.2 23.4 15.9 37.6 15.9 14.3 0 27.6-5.6 37.6-15.8L469 285.6c53.5-54.7 64.7-157.9-10.6-221.3zm-23.6 187.5L259.4 430.5c-2.4 2.4-4.4 2.4-6.8 0L77.2 251.8c-36.5-37.2-43.9-107.6 7.3-150.7 38.9-32.7 98.9-27.8 136.5 10.5l35 35.7 35-35.7c37.8-38.5 97.8-43.2 136.5-10.6 51.1 43.1 43.5 113.9 7.3 150.8z"></path></svg>
                        </span>
                      </span>
                      <span v-else>
                        <favourite-loading-spinner />
                      </span>
                      <span class="ml-3">Favourite</span>
                    </div>
                    <div class="top-container lg:pr-48 xl:pr-72">
                      <h1 class="uppercase font-bold">{{winery.elements.name.value}}</h1>
                      <div class="location opacity-50 mt-4">{{winery.elements.state__zone__region.value}}<span v-if="winery.elements.state.value && winery.elements.state.value.indexOf(',') === -1">, {{winery.elements.state.value}}</span></div>
                      <div class="brands mt-16" v-if="winery.elements.brand_name_s.value">
                        <div class="font-bold opacity-50">Brands</div>
                        <div class="brand-list">{{winery.elements.brand_name_s.value}}</div>
                      </div>
                      <tags class="mt-12" :tags="tags" v-if="tags.length" />
                    </div>
                  </div>
                  <winery-bio-accordion class="mt-16" :winery="winery"></winery-bio-accordion>
                  <winery-video :video-url="winery.elements.video_link.value" class="mt-16" v-if="winery.elements.video_link.value.length" />
                  <downloads class="mt-16" :files="uniqueDocs" :winery-id="winery.elements.winery_id.value" />
                  <winery-notes v-if="$auth.isAuthenticated" class="mt-16" heading="Your notes" intro="Make any notes about this winery to save in your profile. Your comments will only be seen by you." :winery-id="winery.elements.winery_id.value ? winery.elements.winery_id.value : '0'"/>
                </div>
                <aside class="hidden md:block md:sticky md:top-40 lg:top-60 md:w-96 lg:w-380 flex-shrink-0 md:ml-16 lg:ml-24 xl:ml-56">
                  <bleed-container bleed="right">
                    <div class="bg-grey-100 p-8 lg:p-16">
                      <div class="bleed-wrapper">
                        <winery-share :winery="winery" />
                      </div>
                    </div>

                  </bleed-container>
                  <div class="affw" v-if="winery.elements.affw.value.length">
                    <router-link to="/discover/collections/affw"><img src="@/assets/affw-white.png"></router-link>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>

        <other-wines-carousel v-if="products && products.length" class="mt-16 md:mt-32" heading="Some of our wines —" :products="products" />

        <curated-collections v-if="collections.length" :collections="collections" :modular-content="collectionsContent" :show-spotlight="false" heading="Featured in collections —" />


        <div class="outer-container md:hidden">
          <div class="inner-container">
            <div class="bg-grey-100 p-8 lg:p-16 mt-16">
              <winery-share :winery="winery" />
            </div>
            <div class="affw" v-if="winery.elements.affw.value.length">
              <router-link to="/discover/collections/affw"><img src="@/assets/affw-white.png"></router-link>
            </div>
          </div>
        </div>

        <winery-connect :winery="winery" v-if="$auth.isAuthenticated" />
      </div>
    </div>
  </div>
</template>

<script>
import Analytics from '@/analytics'
import BleedContainer from "@/components/BleedContainer";
import WineryBioAccordion from "@/components/WineryBioAccordion";
import OtherWinesCarousel from "@/components/OtherWinesCarousel";
import WineryShare from "@/components/WineryShare";
import Tags from "@/components/Tags";
import Downloads from "@/components/Downloads";
import WineryConnect from "@/components/WineryConnect";
import LoadingSpinner from "@/components/LoadingSpinner";
import Kontent from "@/kontent";
import WineryNotes from "@/components/WineryNotes";
import FavouriteLoadingSpinner from "@/components/FavouriteLoadingSpinner";
import Bus from "@/events/EventBus";
import WineryVideo from "@/components/WineryVideo";
import CuratedCollections from "@/components/CuratedCollections";
import ExploreConnect from "@/components/ExploreConnect";
export default {
  name: 'Winery',
  metaInfo () {
    if (!this.winery) {
      return null
    }

    let meta = [
      {property: 'og:site_name', content: 'CONNECT'},
      {property: 'og:url', content: window.location.href},
      {property: 'og:title', content: this.winery.elements.name.value},
      {property: 'og:description', content: this.winery.elements.winery_overview.value},
      {name: 'title', content: this.winery.elements.name.value},
      {name: 'description', content: this.winery.elements.winery_overview.value},
      {name: 'twitter:card', content: 'summary_large_image'},
      {name: 'twitter:title', content: this.winery.elements.name.value},
      {name: 'twitter:description', content: this.winery.elements.winery_overview.value}
    ]

    // if there's an image
    if (this.winery.elements.hero_image.value.length) {
      meta.push({property: 'og:image', content: `${this.winery.elements.hero_image.value[0].url}?w=767&h=250&fit=crop&auto=format`})
      meta.push({name: 'twitter:image', content: `${this.winery.elements.hero_image.value[0].url}?w=767&h=250&fit=crop&auto=format`})
    }

    return {
      title: this.winery.elements.name.value,
      meta: meta
    }
  },
  components: {
    ExploreConnect,
    CuratedCollections,
    WineryVideo,
    FavouriteLoadingSpinner,
    WineryNotes,
    LoadingSpinner,
    WineryConnect,
    Downloads, Tags, WineryShare, OtherWinesCarousel, WineryBioAccordion, BleedContainer},
  props: {},
  data () {
    return {
      winery: null,
      products: [],
      favouriteLoading: false,
      collections: [],
      collectionsContent: {},
      alpacaVisible: false
    }
  },
  mounted () {
    Bus.$on('favourite-wineries', () => {
      this.favouriteLoading = false
    })

    Kontent.getItem('winery', this.$route.params.winery, 0).then((res) => {
      this.winery = res.data.items[0]
      Analytics.trackPage('Winery: ' + this.winery.elements.name.value)

        if (this.$auth.isAuthenticated) {
            this.$store.dispatch("getFavouriteProducts")
            this.$store.dispatch("getFavouriteWineries")
            this.$store.dispatch("getViewedWineries")
        }



      Kontent.getProductsByWinery(this.winery.system.codename).then((res) => {
        this.products = res.data.items.map((item) => {
          return {
            ...item,
            elements: {
              ...item.elements,
              winery_slug: {
                value: item.elements.winery.value.length ? res.data.modular_content[item.elements.winery.value[0]].elements.slug.value : 'missing-slug'
              }
            }
          }
        })

        // get collections
        Kontent.getWineryCollections(this.productCodenames.join(',')).then((res) => {
          this.collections = res.data.items
          this.collectionsContent = res.data.modular_content
        })
      })
    })
  },
  methods: {
    toggleFavourite () {
        if (this.$auth.isAuthenticated) {
            this.favouriteLoading = true
            if (this.isFavourite) {
                this.$store.dispatch('removeFavouriteWinery', this.winery.elements.winery_id.value ? this.winery.elements.winery_id.value : '0')
            } else {
                this.$store.dispatch('addFavouriteWinery', this.winery.elements.winery_id.value ? this.winery.elements.winery_id.value : '0')
            }
        } else {
            this.showPopup()
        }
    },
    hideAlpaca () {
      this.alpacaVisible = false
    },
      showPopup() {
          Bus.$emit('show-locked-content-popup')
      }
  },
  computed: {
    isFavourite () {
      return this.$store.state.favouriteWineries.includes(this.winery.elements.winery_id.value ? this.winery.elements.winery_id.value : '0')
    },
    isViewed () {
      return this.$store.state.viewedWineries.includes(this.winery.elements.winery_id.value ? this.winery.elements.winery_id.value : '0')
    },
    // favouriteLoading () {
    //   // return this.$store.state.favouriteWineriesLoading
    //   return false
    // },
    uniqueDocs () {
      return this.winery.elements.documents.value.filter((v,i,a)=>a.findIndex(t=>(t.name === v.name))===i)
    },
    productCodenames () {
      return this.products.map((item) => {
        return item.system.codename
      })
    },
    tags () {
      if (!this.winery) {
        return []
      }
      return [...this.winery.elements.properties.value, ...this.winery.elements.additional_services.value]
    }
  },
  beforeDestroy() {
    this.$store.dispatch("addViewedWinery", this.winery.elements.winery_id.value ? this.winery.elements.winery_id.value : '0')
    Bus.$off('favourite-wineries')
  }
}
</script>

<style scoped lang="scss">

.affw {
  padding: 30px;
}

.hero-image-height {
  height: 250px;

  @media(min-width: 768px)
  {
    height: 450px;
  }

  @media(min-width: 1024px)
  {
    height: 600px;
  }
}

.alpaca-popup {
  position: fixed;
  z-index: 1000;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(#000, 0.8);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .close {
    position: absolute;
    right: 10px;
    top: 5px;
    padding: 10px;
    background-color: #000;
    z-index: 2;
  }
}

.alpaca-container {
  box: horizontal middle center;
  position: absolute;
  left: 10px;
  right: 10px;
  top: 10px;
  bottom: 10px;
  z-index: 1;

  .loading {
    opacity: 0.3;
  }
}
</style>

<style lang="scss">
.alpaca-container {
  .branding-bar {
    display: none !important;
  }
  .sticky-header, .map-top-right, .expand, .map-center-right.behaviour-location-wrapper {
    display: none !important;
  }
}
</style>
